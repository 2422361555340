const appConfig = {
  servicePrefix: {
    local: {
      auth: "http://localhost:40020",
    },
    production: {
      auth: "https://apimunawwir.rminu.id/auth",
    },
  },
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: "production",
};

export default appConfig;
