import PopNotification from "components/shared/PopNotification";
import printJS from 'print-js';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();

  const timeString = `${year}${month}${day}${hour}${minute}${second}`;
  return timeString;
};

export const exportXls = async (api, fileName = "data", params) => {
  try {
    let response = await api(params)

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}_${generateTimeString()}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log(e)
    PopNotification("danger", "error", "Download File gagal.")
  }
};

export const printDoc = async (api, data, setIsPrinting) => {
  try {
    setIsPrinting(true)
    let response = await api(data)

    const blob = new Blob([response.data], {
      type: "application/pdf",
    });

    const uri = URL.createObjectURL(blob);
    printJS({
      printable: uri,
      type: "pdf",
      onPrintDialogClose: function () {
        setIsPrinting(false)
      },
      onError: (e) => {
        console.log(e)
      }
    });
    URL.revokeObjectURL(uri);
  } catch (e) {
    PopNotification("danger", "error", e)
  }
};

export const printReceipt = () => {
  const iframe = document.createElement('iframe');
  iframe.src = `/report/receipt.html`;
  iframe.style.width = '0';
  iframe.style.height = '0';

  document.body.appendChild(iframe);

  return () => {
    if (iframe.parentNode) {
      iframe.parentNode.removeChild(iframe);
    }
  };
}